<template>
    <v-dialog
        ref="dialog"
        v-model="modal"
        persistent
        scrollable
        :width="width"
        @click:outside="cancel">
        <template v-slot:activator="{ on, attrs }">
            <v-btn  
                v-if="!hideButton"
                @click.stop="openDialog"
                :block="block"
                :loading="isLoading"
                :disabled="disabled"
                :icon="icon != null && text == null" 
                :large="large"
                :text="text != null && !isButton"
                :small="small"
                v-bind="attrs" 
                v-on="on"
                :class="buttonClass"
                :title="title">
                <slot name="buttonSlot" v-bind:item="selectedItem">
                    <v-icon v-if="icon != null" :large="large" :left="text != null" :small="small">{{ icon }}</v-icon>
                    {{ text }}
                </slot>
            </v-btn>
        </template>
        <v-card
            :flat="flat"
            :loading="isLoading">
            <v-card-title>{{ label }}</v-card-title>
            <v-card-text>
                <v-slide-x-transition>
                    <v-text-field
                        v-if="canSearch"
                        flat
                        dense
                        hide-details
                        hide-no-data
                        solo-inverted
                        single-line
                        prepend-inner-icon="mdi-close"
                        append-icon="mdi-magnify"
                        label="Find"
                        v-model="searchInput"
                        @click:prepend-inner="searchInput = null" />
                </v-slide-x-transition>
                <v-list 
                    v-if="isLengthyArray(filteredItems)"
                    :height="height">
                    <v-list-item-group
                        v-model="selectedItem"
                        :active-class="activeClass"
                        :multiple="multiple">
                        <template v-for="(fItem, index) in filteredItems">
                            <v-list-item
                                v-if="fItem != null"
                                :active-class="activeClass"
                                :key="index"
                                :two-line="twoLine"
                                :three-line="threeLine"
                                :dense="dense"
                                :value="fItem">
                                <slot v-bind:item="fItem">
                                    <div>{{ itemText ? getNestedValue(fItem, itemText) : fItem }}</div>
                                </slot>
                            </v-list-item>
                            <v-divider v-if="dividers" :key="'d' + index" />
                        </template>
                    </v-list-item-group>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <v-btn text @click="cancel">{{ cancelText }}</v-btn>
                <v-btn text @click="save">{{ okText }}</v-btn>
            </v-card-actions>
            <v-alert v-model="showError" dismissible type="error">{{ errorMessage }}</v-alert>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'BT-Select-List-Dialog',
    data: function() {
        return {
            asyncItems: [],
            item: null,
            modal: false,
            isLoading: false,
            searchInput: null,
            selectedItem: null,
            hasOpened: false,
            errorMessage: null,
            showError: false,
        }
    },
    props: {
        activeClass: {
            type: String,
            default: 'primary'
        },
        block: {
            type: Boolean,
            default: false
        },
        buttonClass: {
            type: String,
            default: null
        },
        cancelText: {
            type: String,
            default: 'Cancel'
        },
        canSearch: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        dividers: {
            type: Boolean,
            default: true
        },
        flat: {
            type: Boolean,
            default: false,
        },
        height: {
            type: String,
            default: 'auto'
        },
        hideButton: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: 'mdi-plus'
        },
        isButton: {
            type: Boolean,
            default: false
        },
        isSingle: {
            type: Boolean,
            default: false
        },
        itemID: {
            type: String,
            default: null
        },
        items: {
            type: Array,
            default: null
        },
        itemText: {
            type: String,
            default: 'text'
        },
        itemValue: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        large: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        navigation: {
            type: String,
            default: null
        },
        okText: {
            type: String,
            default: 'OK'
        },
        onFilter: {
            type: Function,
            default: null
        },
        onPullSuccessAsync: {
            type: Function,
            default: null
        },
        params: {
            type: Object,
            default: null //() => { return { }}
        },
        refreshToggle: {
            type: Boolean,
            default: false
        },
        returnObject: {
            type: Boolean,
            default: false
        },
        searchProps: {
            type: Array,
            default: null
        },
        show: {
            type: Boolean,
            default: false
        },
        showToggle: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        twoLine: {
            type: Boolean,
            default: false
        },
        threeLine: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        value: null,
        width: {
            type: String,
            default: 'auto'
        }
    },
    watch: {
        loading(val) {
            this.isLoading = val;
        },
        refreshToggle: function() {
            this.pullItems(true);
        },
        show: function(val) {
            this.modal = val;
        },
        showToggle() {
            if (this.modal === false) {
                this.pullItems();
            }
            
            this.modal = true;
        },
    },
    created() {
        this.selectedItem = this.value;
        this.modal = this.show;
    },   
    computed: {
        filteredItems() {
            var l = this.onFilter ? this.onFilter(this.asyncItems) : this.asyncItems;
            
            if (this.canSearch && this.searchInput != null) {
                l = l.filter(x => this.hasSearch(x, this.searchInput, this.searchableProperties));
            }

            return l;
        },
        searchableProperties() {
            var l = []

            if (this.itemText != null) {
                l.push(this.itemText);
            }

            if (this.isLengthyArray(this.searchProps)) {
                l = [...new Set(l.concat(this.searchProps))];
            }

            return l;
        },
    },
    methods: {   
        formError(err) {
            this.showError = true;
            this.errorMessage = this.extractErrorDescription(err);
        },
        startLoading() {
            this.isLoading = true;
            this.$forceUpdate();
        },
        endLoading() {
            this.isLoading = false;
            this.$forceUpdate();
        },
        cancel() {
            this.$emit('cancel');
            this.modal = false;
        },
        async openDialog() {
            await this.pullItems();
        },
        async pullItems(refresh = false) {
            if (this.items != null) {
                this.asyncItems = this.items;
                return;
            }

            if (this.navigation == null) {
                return;
            }

            try {
                this.startLoading();
                var res = null;
                if (this.isSingle) {
                    res = await this.$BlitzIt.store.get(this.navigation, this.itemID, this.params, refresh);
                }
                else {
                    res = await this.$BlitzIt.store.getAll(this.navigation, this.params, refresh);
                }

                if (this.onPullSuccessAsync != null) {
                    this.asyncItems = await this.onPullSuccessAsync(res, refresh);
                }
                else {
                    this.asyncItems = res;
                }
            }
            catch (err) {
                this.formError(err);
            }
            finally {
                this.endLoading();
            }
            
        },
        refresh() {
            this.showError = false;
            this.errorMessage = null;
            this.pullItems(true);
        },
        save() {
            this.$emit('ok', this.multiple ? this.selectedItem : [this.selectedItem]);
            this.modal = false;
        }
    }
}
</script>